import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="July 2019" subnav="release-notes">
      <div id="July2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          This release may be small, but it sure packs a punch. There are a lot
          of new components for React Native, and we spent some time tidying up
          our docs. There's even a new pattern to represent our users, groups or
          organizations in lists.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                The <Link href="/patterns/user-lists">user lists</Link> pattern.
              </Added>
              <Improved>Getting started steps to include Figma.</Improved>
              <Fixed>
                An issue when viewing HEX values in place of the RGBA values
                resulted in a blank page.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="7.3.0"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v7.3.0"
            />
            <div className={styles.changesList}>
              <Added>
                <Link href="/components/avatars/design">Avatars</Link> for
                groups, organizations, teams and users.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/notifications/note/design">note</Link>{' '}
                component.
              </Added>
              <Added>
                Both the{' '}
                <Link href="/components/forms/checkbox/design">checkbox</Link>{' '}
                and <Link href="/components/forms/radio/design">radio</Link> to
                start building forms.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
